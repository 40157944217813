import React from "react"
import styles from "../components/header.module.css"
import Header from "../components/header"
import { Link } from "gatsby"

export default () => (
  <Header>
    <div className={styles.mainheading}>Aviva Melamed</div>
    <div className={styles.subheading}>Humanistic Integrative Counsellor</div>
    <div className={styles.box} />
    <div className={styles.menubox}>
      <ul className={styles.ul}>
        <li className={styles.li}>
          <Link to="/">Home</Link>
        </li>
        <li className={styles.li}>
          <Link to="/about">About Me</Link>
        </li>
        <li className={styles.li}>
          <Link to="/faq">FAQ</Link>
        </li>
        <li className={styles.li}>
          <Link to="/services">Services</Link>
        </li>
        <li className={styles.li}>
          <Link to="/contact">Contact Me</Link>
        </li>
      </ul>
    </div>
    <div className={styles.logo} />
    <div className={styles.contact}>Contact Me</div>
    <div className={styles.contactphone} />
    <div className={styles.contactmail} />
    <div className={styles.contactdetails}>
      <b>Telephone</b>
      <br />
      <br />
      +44(0)7413 299171‬
      <br />
      <br />
      <b>E-mail</b>
      <br />
      <br />
      <a
        href="mailto:info@avivacounsellingservices.com?Subject=Contact%20Request&body=Date%3A%20%0D%0ATime%3A%20%0D%0ADescription%3A%20"
        target="_top"
      >
        info@avivacounsellingservices.com
      </a>
    </div>
    <div className={styles.contactbottom}>Looking forward to meeting you</div>
  </Header>
)
